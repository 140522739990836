/* Navbar */

.navbar {
	font-weight: 400;
	transition: background $action-transition-duration $action-transition-timing-function;
	-webkit-transition: background $action-transition-duration $action-transition-timing-function;
	-moz-transition: background $action-transition-duration $action-transition-timing-function;
	-ms-transition: background $action-transition-duration $action-transition-timing-function;
	.navbar-brand-wrapper {
		background: $navbar-brand-bg;
		padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
		.sidebar-dark & {
			background: $sidebar-dark-bg;
		}
		transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
		-webkit-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
		-moz-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
		-ms-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
		width: $sidebar-width-lg;
		height: $navbar-height;
		.navbar-brand {
			color: lighten(color(gray-dark), 20%);
			font-size: 1.5rem;
			margin-right: 0;
			padding: .25rem 0;
			&.brand-logo-mini {
				display: none;
			}
			&:active,
			&:focus,
			&:hover {
				color: lighten(color(gray-dark), 10%);
			}
			img {
				width: calc(#{$sidebar-width-lg} - 115px);
				max-width: 100%;
				height: 22px;
				margin: auto;
				vertical-align: middle;
			}
		}
		.navbar-toggler {
			border: 0;
			color: $navbar-toggler-color;
			font-size: 1.375rem;
			padding: 0;
			&:not(.navbar-toggler-right) {
				@media (max-width: 991px) {
					display: none;
				}
			}
			&.navbar-toggler-right {
				@media (max-width: 991px) {
					padding-left: 15px;
					padding-right: 11px;
				}
			}
		}
		.brand-logo-mini {
			padding-left: 0;
			text-align: center;
			img {
				width: calc(#{$sidebar-width-icon} - 30px);
				max-width: 100%;
				margin: auto;
			}
		}
	}
	.navbar-menu-wrapper {
		background: $navbar-default-bg;
		transition: width $action-transition-duration $action-transition-timing-function;
		-webkit-transition: width $action-transition-duration $action-transition-timing-function;
		-moz-transition: width $action-transition-duration $action-transition-timing-function;
		-ms-transition: width $action-transition-duration $action-transition-timing-function;
		color: $navbar-menu-color;
		padding-left: 2.5rem;
		padding-right: 2.5rem;
		width: calc(100% - #{$sidebar-width-lg});
		height: $navbar-height;
		-webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
		@media (max-width: 991px) {
			width: calc(100% - 55px);
			padding-left: 15px;
			padding-right: 15px;
		}
		.navbar-nav {
			@extend .d-flex;
			@extend .flex-row;
			@extend .align-self-stretch;
			.nav-item {
				margin-left: .75rem;
				margin-right: .75rem;
				&:last-child {
					margin-right: 0;
				}
				.nav-link {
					color: inherit;
					font-size: $navbar-font-size;
				}
				&.nav-search {
					margin-left: 2rem;
					.input-group {
						background: rgba($white, .13);
						border-radius: 4px;
						padding: 0.625rem 1.625rem;
						border: 1px solid $border-color;
						border-radius: 0.375rem;
						.form-control,
						.input-group-text {
							background: transparent;
							border: 0;
							color: $navbar-search-font-color;
							padding: 0;
							i {
								color: $navbar-search-font-color;
								font-size: 13px;
							}
						}
						.form-control {
							margin-left: .5rem;
							font-size: 10px;
							@include placeholder {
								color: $navbar-search-placeholder-color;
								font-size: 10px;
							}
						}
					}
				}
				&.nav-settings {
					@extend .align-self-stretch;
					@extend .align-items-center;
					border-left: 1px solid rgba($white, .24);
					margin: 0;
					padding-left: .5rem;
					.nav-link {
						padding: 0;
						text-align: center;
					}
					i {
						font-size: 1.5rem;
						vertical-align: middle;
					}
				}
				&.nav-profile {
					@extend .d-flex;
					@extend .align-items-center;
					img {
						width: 30px;
						height: 30px;
						border-radius: 100%;
					}
					.nav-profile-name {
						margin-left: .5rem;
						@media (max-width: 767px) {
							display: none;
						}
					}
				}
				&.nav-user-icon {
					@extend .d-flex;
					@extend .align-items-center;
					img {
						width: 35px;
						height: 35px;
					}
				}
				&.dropdown {
					.dropdown-menu {
						@extend .dropdownAnimation;
						border: none;
						-webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
						-moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
						box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
					}
					.navbar-dropdown {
						position: absolute;
						font-size: 0.9rem;
						margin-top: 0;
						right: 0;
						left: auto;
						padding: 0;
						top: $navbar-height;
						border-radius: 4px;
						.rtl & {
							right: auto;
							left: 0;
						}
						.dropdown-item {
							@extend .d-flex;
							@extend .align-items-center;
							margin-bottom: 0;
							padding: .65rem 1.5rem;
							i {
								font-size: 17px;
							}
							.badge {
								margin-left: 2.5rem;
							}
							.ellipsis {
								max-width: 200px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							.rtl & {
								i {
									@extend .mr-0;
									margin-left: 10px;
								}
								.badge {
									margin-left: 0;
									margin-right: 2.5rem;
								}
							}
						}
						.dropdown-divider {
							margin: 0;
						}
					}
					i {
						margin-right: .5rem;
						vertical-align: middle;
					}
					@media (max-width: 991px) {
						position: static;
						.navbar-dropdown {
							left: 20px;
							right: 20px;
							top: $navbar-height;
							width: calc(100% - 40px);
						}
					}
					.count-indicator {
						position: relative;
						padding: 0;
						text-align: center;
						&:hover {
							color: theme-color(dark);
						}
						i {
							font-size: 1.25rem;
							margin-right: 0;
							vertical-align: middle;
						}
						.count {
							position: absolute;
							left: 35%;
							width: 6px;
							height: 6px;
							border-radius: 100%;
							background: theme-color(danger);
							top: -2px;
						}
						&:after {
							display: none;
						}
					}
				}
				.nav-icon-rounded {
					width:38px;
					height: 38px;
					padding-top: 0;
					overflow: hidden;
					font-size: 16px;
					text-align: center;
					.flag-icon {
						background-size: 35px 35px;
						background-position: 0px 0px;
						line-height: normal;
            border-radius: 100%;
            width:35px;
					  height: 35px;
					}
				}
			}
			&.navbar-nav-right {
				@extend .align-self-stretch;
				@extend .align-items-stretch;
				.nav-item {
					@include display-flex;
					@extend .align-items-center;
				}
				@media (min-width: 992px) {
					margin-left: auto;
					.rtl & {
						margin-left: 0;
						margin-right: auto;
					}
				}
			}
		}
	}
	/* Navbar color variations */
	@each $color,
	$value in $theme-colors {
		&.navbar-#{$color} {
			.navbar-menu-wrapper {
        background: $value;
				.nav-item {
					.btn-outline-secondary {
						color: $white;
						border: none;
					}
					&.nav-search {
						.input-group {
							border: none;
							.form-control,
							.input-group-text {
								color: $white;
								i {
									color: $white;
								}
							}
							.form-control {
								@include placeholder {
									color: $white;
								}
							}
						}
					}
				}
			}
			&:not(.navbar-light) {
				.navbar-menu-wrapper {
					.navbar-toggler {
						color: $white;
					}
					.nav-item {
						.nav-link {
							color: $white;
						}
					}
				}
			}
		}
		&.navbar-dark {
			.navbar-menu-wrapper {
				border-left-color: darken($border-color, 50%);
			}
		}
		&.navbar-danger {
			.navbar-menu-wrapper {
				.navbar-nav {
					.nav-item.dropdown {
						.notification-ripple-bg {
							background-color: $white;
						}
					}
				}
			}
		}
	}
}

@media (max-width:991px) {
	.navbar {
		flex-direction: row;
		.navbar-brand-wrapper {
			width: 55px;
			.navbar-brand {
				&.brand-logo {
					display: none;
				}
				&.brand-logo-mini {
					display: inline-block;
				}
			}
		}
	}
	.navbar-collapse {
		@include display-flex;
		margin-top: 0.5rem;
	}
}

@media (max-width:480px) {
	.navbar {
		.navbar-brand-wrapper {
			width: 55px;
			.brand-logo-mini {
				padding-top: 0px;
			}
		}
	}
}